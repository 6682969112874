import { createLoadingSpinner } from "../utils";

export default class EmailInputScreen {

    /**
     *
     * @param modalContainer
     * @param baseApiUrl
     * @param onSignInRequired
     * @param onAccountCreationRequired
     */
    constructor({ modalContainer, baseApiUrl, onSignInRequired, onAccountCreationRequired }) {

        this.modalContainer = modalContainer;
        this.baseApiUrl = baseApiUrl;
        this.onSignInRequired = onSignInRequired;
        this.onAccountCreationRequired = onAccountCreationRequired;

        this.verifyEmail = this.verifyEmail.bind(this);

        /** Email modal */
        this.emailModal = document.querySelector('#enrol-modal-container #email-modal');

        /** Button inside email modal */
        this.emailModalBtn = document.querySelector('#enrol-modal-container #email-modal button');

        /** Email input field in enrol modal */
        this.emailInput = document.querySelector('#enrol-modal-container #email-modal input');

        this.abortCheckEmail = new AbortController();
    }

    setup() {
        this.modalContainer.classList.add('is--open');
        document.body.classList.add('no-scroll');
        this.modalContainer.querySelector('#email-modal').classList.remove('hidden');
        this.modalContainer.querySelector('#email-modal input').focus();
        this.emailModal.addEventListener('submit', this.verifyEmail);

        // Bind event listeners
        this.emailModalBtn?.addEventListener('click', this.verifyEmail);

        //reset abort controller
        this.abortCheckEmail = new AbortController()
    }

    tearDown() {
        this.emailModalBtn.removeEventListener('click', this.verifyEmail);
        this.emailModal.removeEventListener('submit', this.verifyEmail);
        this.modalContainer.classList.remove('is--open');
        document.body.classList.remove('no-scroll');
        this.modalContainer.querySelector('#email-modal').classList.add('hidden');
        //abort fetch request
        this.abortCheckEmail.abort();
    }

    /**
     * Check if email is valid and exists in the database
     */
    verifyEmail(e) {
        e.preventDefault();

        // clear errors
        this.emailModal.querySelectorAll('.error').forEach(msg => {
            msg.classList.add('hidden');
        });
        this.emailModal.querySelector('.form__input-error')?.classList.remove('form__input-error');

        if (this.validateEmail(this.emailInput.value)) {
            this.checkEmail();
        } else {
            this.emailModal.querySelector('input').classList.add('form__input-error');
            this.emailModal.querySelector('#enrol__email-error').classList.remove('hidden');
        }
    }

    /**
     * Check if string is a valid email address
     */
    validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    /**
     * Check if the email exists in the database
     * @see https://dev.edufocal.com/docs/edufocal-api/95603980bd232-email-check
     */
    checkEmail() {
        const email = this.emailModal.querySelector('input').value;
        const loadingElem = createLoadingSpinner();
        const emailBtnWrapper = this.emailModal.querySelector(
            '#enrol-modal-container #email-modal-btn-wrapper'
        );

        // Add loading spinner to email modal and hide 'continue' button
        emailBtnWrapper.appendChild(loadingElem);
        this.emailModal
            .querySelector('#enrol-modal-container #email-modal-btn-wrapper button')
            .classList.add('hidden');
        // disable 'continue' button to prevent multiple requests
        emailBtnWrapper.classList.add('no-clicks');

        fetch(`${this.baseApiUrl}/oauth/check`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
            signal: this.abortCheckEmail.signal,
        })
            .then(data => {
                if (data.status === 429) {
                    // too many login attempts
                    this.emailModal.querySelector('#enrol__too-many-attempts').classList.remove('hidden');
                    return;
                }
                if (data.status === 404) {
                    // email doesn't exist, begin enrolment process
                    this.emailModal.classList.add('hidden');
                    this.onAccountCreationRequired(email);
                }
                if (data.status === 200) {
                    this.onSignInRequired(email);
                }
            })
            .catch(er => {
                if (er.name === 'AbortError') return;
                console.error(er.message);
            })
            .finally(() => {
                // Remove loading elem and show continue button
                loadingElem.remove();
                this.emailModalBtn.classList.remove('hidden');
                emailBtnWrapper.classList.remove('no-clicks');
            });
    }

}