import {createLoadingSpinner, getApiDomainToken} from "../utils";

export default class PlanSelectionScreen {

    constructor({ modalContainer, baseApiUrl, category }) {

        this.modalContainer = modalContainer;
        this.baseApiUrl = baseApiUrl;
        this.category = category;

        /** The modal which prompts the user to select an exam */
        this.selectExamModal = document.querySelector('#select-exam-modal');

        /** "Continue" button in exam selection modal */
        this.selectExamBtn = document.querySelector('#select-exam-modal #continue-btn');

        /** Container element for the list of plans/exams */
        this.examOptions = document.querySelector('#exam-options');

        /** Refetch plans button */
        this.refetchPlansBtn = document.querySelector('#refetch-plans-btn');

        /** The currently selected exam */
        this.selectedExamID = null;

        /** The currently selected school */
        this.selectedSchoolID = null;

        /** The list of exams */
        this.exams = [];

        /** The list of schools */
        this.schools = [];

        /** The list of plan/exam prices */
        this.planPrices = [];

        /** The currently selected plan ID */
        this.planPriceID = null;

        /** Fetching exams/plans modal */
        this.fetchingPlansModal = document.querySelector('#requesting-plans-modal');

        /** Error fetching exams/plans modal */
        this.fetchingPlansErrorModal = document.querySelector('#plans-error-modal');

        /** The modal which prompts the user to select an exam */
        this.selectExamModal = document.querySelector('#select-exam-modal');

        /** "Continue" button in exam selection modal */
        this.selectExamBtn = document.querySelector('#select-exam-modal #continue-btn');

        /** Container element for the list of plans/exams */
        this.examOptions = document.querySelector('#exam-options');

        /** Container for plans */
        this.planContainer = document.querySelector('.plan-container');

        /** Refetch plans button */
        this.refetchPlansBtn = document.querySelector('#refetch-plans-btn');

        /** The currently selected exam */
        this.selectedExamID = null;

        /** The currently selected school */
        this.selectedSchoolID = null;

        /** The list of exams */
        this.exams = [];

        /** The list of schools */
        this.schools = [];

        /** The list of plan/exam prices */
        this.planPrices = [];

        /** The currently selected plan ID */
        this.planPriceID = null;

        /** Button inside plan prices modal (request payment URL) */
        this.planPricesBtn = document.querySelector('#exam-prices-modal #continue-btn');

        /** Container for plan/exam tabs */
        this.tabContainer = document.querySelector('.tab-container');

        /** Exam/Plan prices modal */
        this.planPricesModal = document.querySelector('#exam-prices-modal');

        this.openPlanPriceModal = this.openPlanPriceModal.bind(this);
        this.refetchPlans = this.refetchPlans.bind(this);
        this.goToPaymentUrl = this.goToPaymentUrl.bind(this);
        this.handleExamClick = this.handleExamClick.bind(this);

    }

    setup() {
        console.log("setup for plan selection screen");
        this.modalContainer.classList.add('is--open');
        document.body.classList.add('no-scroll');

        this.planPricesBtn.addEventListener('click', this.goToPaymentUrl);
        this.refetchPlansBtn.addEventListener('click', this.refetchPlans);
        this.selectExamBtn.addEventListener('click', this.openPlanPriceModal);

        this.fetchingPlansModal.classList.remove('hidden');

        this.fetchPlans()
            .then(plans => {
                this.exams = plans;
                this.populateExams();
                this.addExamSelectionListeners();
                this.openSelectExamModal();
            })
            .catch(() => {
                this.fetchingPlansModal.classList.add('hidden');
                this.fetchingPlansErrorModal.classList.remove('hidden');
            });
    }

    tearDown() {
        console.log("teardown for plan selection screen");
        this.planPricesBtn.removeEventListener('click', this.goToPaymentUrl);
        this.refetchPlansBtn.removeEventListener('click', this.refetchPlans);
        this.selectExamBtn.removeEventListener('click', this.openPlanPriceModal);
        this.modalContainer.classList.remove('is--open');
        this.selectExamModal.removeEventListener('click', this.handleExamClick);
        document.body.classList.remove('no-scroll');
    }

    /**
     * Fetches the list of exams/plans from the server
     * @see https://dev.edufocal.com/docs/edufocal-api/27c034c84cecd-list-available-plans
     */
    async fetchPlans() {
        const examCategory = 'pep';
        const token = await getApiDomainToken();
        const url = `${this.baseApiUrl}/onboarding/plan?filter[category]=${this.category}&exam_category=${examCategory}`;

        try {
            const res = await fetch(`${url}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await res.json();
            return data.plans;
        } catch (err) {
            throw new Error(err);
        }
    }

    /**
     * Open the plan/exam price modal
     */
    openPlanPriceModal() {
        const loader = createLoadingSpinner();
        const errorMessage = this.selectExamModal.querySelector('.error-card');

        // hide error message if it's visible
        if (!errorMessage.classList.contains('hidden')) {
            errorMessage.classList.add('hidden');
        }

        // Add loading effect
        this.selectExamBtn.classList.add('hidden', 'no-clicks');
        this.selectExamModal.querySelector('.modal__content__footer').appendChild(loader);

        this.fetchPlanPrices(this.selectedExamID)
            .then(prices => {
                this.planPrices = prices;
                this.populatePlanPriceModal();
                this.selectExamModal.classList.add('hidden');
                this.planPricesModal.classList.remove('hidden');
            })
            .catch(() => {
                errorMessage.classList.remove('hidden');
            })
            .finally(() => {
                this.selectExamBtn.classList.remove('hidden', 'no-clicks');
                loader.remove();
            });
    }

    /**
     * Populate the plan/exam price modal with the list of plans
     */
    populatePlanPriceModal() {
        // Clear current tabs and plans
        document.querySelectorAll('.tab').forEach(tab => {
            tab.remove();
        });
        document.querySelectorAll('.plan').forEach(plan => {
            plan.remove();
        });

        // Create tabs
        this.planPrices.forEach((plan, index) => {
            const tab = document.createElement('div');
            tab.classList.add('tab');
            tab.dataset.tab = index + 1;
            tab.textContent = plan.name;
            tab.addEventListener('click', () => {
                const tabs = document.querySelectorAll('.tab');
                const plans = document.querySelectorAll('.plan');

                // Remove active class from all tabs and plans
                tabs.forEach(tab => tab.classList.remove('active'));
                plans.forEach(plan => plan.classList.remove('active'));

                // Add active class to clicked tab and corresponding plan
                tab.classList.add('active');
                plans[index].classList.add('active');

                // set active plan
                this.planPriceID = plan.id;
            });
            this.tabContainer.appendChild(tab);
        });

        // Create plans
        this.planPrices.forEach((plan, index) => {
            const planDiv = document.createElement('div');
            planDiv.classList.add('plan');
            planDiv.dataset.tab = index + 1;

            const priceContainer = document.createElement('div');
            priceContainer.classList.add('plan-price-container');
            planDiv.appendChild(priceContainer);

            const price = document.createElement('span');
            price.classList.add('plan-price');
            const formattedPrice = this.formatPrice(plan.display_charge);
            price.innerHTML = `<sup>$</sup><span class="plan-price-value">${formattedPrice}</span>`;
            priceContainer.appendChild(price);

            const currency = document.createElement('span');
            currency.classList.add('plan-price-currency');
            currency.textContent = plan.currency;
            priceContainer.appendChild(currency);

            const title = document.createElement('h2');
            title.classList.add('plan-title');
            title.textContent = plan.name;
            planDiv.appendChild(title);

            const subtitle = document.createElement('h3');
            subtitle.classList.add('plan-subtitle');
            subtitle.textContent = plan.description;
            planDiv.appendChild(subtitle);

            this.planContainer.appendChild(planDiv);
        });

        // Set the first tab and plan to be active
        const tabs = document.querySelectorAll('.tab');
        const plans = document.querySelectorAll('.plan');
        tabs[0].classList.add('active');
        plans[0].classList.add('active');
        this.planPriceID = this.planPrices[0].id;
    }

    /**
     * Format a string to currency format
     * @param {string} price - The string to format
     * @example formatPrice('1000') // returns '1,000'
     */
    formatPrice(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    /**
     * Request payment URL and redirect to it
     */
    async goToPaymentUrl() {
        const loader = createLoadingSpinner();
        const errorMessage = this.planPricesModal.querySelector('.error-card');

        // hide error message if it's visible
        if (!errorMessage.classList.contains('hidden')) {
            errorMessage.classList.add('hidden');
        }

        // Add loading effect
        this.planPricesBtn.classList.add('hidden', 'no-clicks');
        this.planPricesModal.querySelector('.modal__content__footer').appendChild(loader);


        let token = await getApiDomainToken();
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        const url = `${import.meta.env.VITE_EDUFOCAL_PAYMENT_URL}/checkout/${this.planPriceID}`;

        fetch(url, {
            method: 'POST',
            headers,
        })
            .then(res => res.json())
            .then(data => {
                if (data.url) {
                    window.location = data.url;
                }
            })
            .catch(err => {
                errorMessage.classList.remove('hidden');
                this.planPricesBtn.classList.remove('hidden', 'no-clicks');
                loader.remove();
            }).finally(() => {
        });
    }

    /**
     * Fetch plan/exam prices
     * @see https://dev.edufocal.com/docs/edufocal-api/27c034c84cecd-list-available-plans
     * @param {string} id - The ID of the plan/exam
     * @returns {Promise<Array>} The list of prices
     */
    async fetchPlanPrices(id) {
        const token = await getApiDomainToken();

        try {
            const res = await fetch(`${this.baseApiUrl}/onboarding/plan/${id}/prices`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await res.json();
            return data.prices;
        } catch (err) {
            throw new Error(err);
        }
    }

    /**
     * Populate plan/exam list with the list of exams
     */
    populateExams() {
        this.examOptions.innerHTML = '';
        this.exams.forEach(exam => {
            const examOption = document.createElement('button');
            examOption.classList.add('radio-button');
            examOption.setAttribute('type', 'button');
            examOption.setAttribute('data-value', exam.id);
            examOption.textContent = exam.name;
            this.examOptions.appendChild(examOption);
        });

        const options = document.querySelector('#exam-options');

        // if the list of exams is scrollable
        if (this.isScrollable(options)) {
            document.querySelector('.modal__scroll-indicator').classList.remove('hidden');
        }
    }

    /**
     * Adds event listeners to the buttons in the exam selection modal
     */
    addExamSelectionListeners() {
        const modal = document.querySelector('#select-exam-modal');
        modal.addEventListener('click', this.handleExamClick);
    }

    handleExamClick(event) {
        if (event.target.classList.contains('radio-button')) {
            this.selectExam(event.target);
        }
    }

    /**
     * Handles selection of an exam from the exam selection modal
     * @param {HTMLElement} radioBtn - The button that was clicked
     */
    selectExam(radioBtn) {
        if (this.selectedExamID) {
            document.querySelector('.radio-button.selected')?.classList.remove('selected');
        }

        radioBtn.classList.add('selected');
        this.selectedExamID = radioBtn.getAttribute('data-value');

        // enable the continue button if the user has selected an exam
        if (this.selectExamBtn.disabled) {
            this.selectExamBtn.disabled = false;
        }
    }

    /**
     * Opens the modal which prompts the user
     * to select an exam they want to enrol in
     */
    openSelectExamModal() {
        this.fetchingPlansModal.classList.add('hidden');
        this.selectExamModal.classList.remove('hidden');
    }

    /**
     * Refetchs plans/exams
     */
    refetchPlans(e) {
        this.fetchingPlansErrorModal.classList.add('hidden');
    }

    /**
     * Checks if an element is scrollable
     * @param {HTMLElement} element - The element to check
     * @returns {boolean} Whether the element is scrollable
     */
    isScrollable(element) {
        return (
            element.scrollHeight > element.clientHeight &&
            (element.scrollTop > 0 || element.scrollTop < element.scrollHeight - element.clientHeight)
        );
    }
}