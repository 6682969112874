import {setApiSessionToken} from "../utils";

export default class OtpRequestScreen {
    constructor({ baseApiUrl, onLoginSucceeded, onOtpWindowClose, modalContainer }) {
        this.baseApiUrl = baseApiUrl;
        this.onLoginSucceeded = onLoginSucceeded;
        this.modalContainer = modalContainer;
        this.onOtpWindowClose = onOtpWindowClose;

        /** OTP modal */
        this.otpModal = document.querySelector('#otp-modal');

        /** OTP Title */
        this.otpTitle = document.querySelector('.otp-title');

        /** Requesting OTP Modal */
        this.requestingOtpModal = document.querySelector('#requesting-otp-modal');

        /** OTP Request Error Modal */
        this.otpRequestErrorModal = document.querySelector('#otp-request-error-modal');

        /** Close button in OTP request error modal */
        this.otpRequestErrorCloseBtn = document.querySelector('#close-btn-otp-error');

        /** "Continue" button in OTP modal */
        this.otpContinueBtn = document.querySelector('#continue-btn-otp');

        /** Resend OTP Button */
        this.resendOtpBtn = document.querySelector('.resend-otp');

        /** HTML Elem that wraps the OTP button */
        this.resendOtpBtnWrapper = document.querySelector('.no-code');

        /** OTP Input Fields */
        this.otpInputs = document.querySelectorAll('.verification-inputs input');

        /** Email input field */
        this.emailField = document.querySelector('#signup-email');

        this.abortSendOtp = new AbortController();

        this.handleOtpInput = this.handleOtpInput.bind(this);
        this.validateOtp = this.validateOtp.bind(this);
    }

    setup() {
        document.body.classList.add('no-scroll');
        this.modalContainer.classList.add('is--open');
        this.requestingOtpModal.classList.remove('hidden');
        this.otpModal.classList.add('hidden');

        this.otpRequestErrorCloseBtn.addEventListener('click', this.onOtpWindowClose);

        this.resendOtpBtn.addEventListener('click', this.sendOtp.bind(this, true));

        this.handleOtpInput();

        this.otpContinueBtn.addEventListener('click', this.validateOtp);
        this.abortSendOtp = new AbortController();

        this.sendOtp()
            .then(res => {
                const { timeoutMinutes, verbiage } = res;
                const email = this.emailField.value;
                this.requestingOtpModal.classList.add('hidden');
                this.otpModal.classList.remove('hidden');
                this.otpTitle.innerHTML = `We ${verbiage} a one-time-password (OTP) to <strong>${email}</strong>. It will expire in <strong>${timeoutMinutes}</strong> minutes.`;
            })
            .catch((e) => {
                this.requestingOtpModal.classList.add('hidden');
                this.otpRequestErrorModal.classList.remove('hidden');
            });
    }

    tearDown() {
        this.abortSendOtp.abort();
        document.body.classList.remove('no-scroll');
        this.requestingOtpModal.classList.add('hidden');
        this.modalContainer.classList.remove('is--open');
        this.otpRequestErrorCloseBtn.removeEventListener('click', this.onOtpWindowClose);

        this.resendOtpBtn.removeEventListener('click', this.sendOtp.bind(this, true));

        this.otpInputs.forEach(input => {
            input.removeEventListener('input', this.handleOtpInput);
        });

        this.otpContinueBtn.removeEventListener('click', this.validateOtp.bind(this));
    }

    /**
     * Gets the OTP from the input fields
     * @returns {string} OTP as a string
     */
    getOtpInputs() {
        let otp = '';
        this.otpInputs.forEach(input => {
            otp += input.value;
        });
        return otp;
    }

    /**
     * Validate OTP
     * @see https://dev.edufocal.com/docs/edufocal-api/a190bbdfed49a-validate-otp
     */
    validateOtp() {
        const otp = this.getOtpInputs();
        const email = this.emailField.value;

        const url = `${this.baseApiUrl}/oauth/otp`;
        const data = {
            email,
            otp,
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(res => {
                // OTP is valid, set in local storage
                if (setApiSessionToken(res.token)) {
                    this.otpModal.classList.add('hidden');
                    this.onLoginSucceeded();
                }
            })
            .catch(() => {
                console.error('Error validating OTP. Please try again later.');
            });
    }

    /**
     * Handle OTP input
     */
    handleOtpInput() {
        this.otpInputs.forEach((input, index) => {
            input.addEventListener('input', e => {
                const target = e.target;
                const value = target.value;

                if (isNaN(value)) {
                    target.value = '';
                    return;
                }

                // Move to next input field if input is valid
                if (value) {
                    if (index < this.otpInputs.length - 1) {
                        this.otpInputs[index + 1].focus();
                    } else {
                        this.otpInputs[index].blur();
                    }
                } else {
                    // Move to previous input field if backspace or delete is pressed
                    if (index > 0) {
                        if (e.inputType === 'deleteContentBackward' || e.inputType === 'deleteContentForward') {
                            this.otpInputs[index - 1].focus();
                        }
                    }
                }

                // Overwrite existing value if input already has a value
                if (target.value.length > 1) {
                    target.value = value.charAt(value.length - 1);
                }
            });
        });

        // Distribute OTP code from the first input field to the rest of the fields
        this.otpInputs[0].addEventListener('paste', e => {
            e.preventDefault();
            const pastedData = e.clipboardData.getData('text/plain').trim();
            const is6Digits = /^\d{6}$/.test(pastedData);

            if (is6Digits) {
                pastedData.split('').forEach((digit, index) => {
                    this.otpInputs[index].value = digit;
                    this.otpInputs[index].dispatchEvent(new Event('input'));
                });
            }
        });
    }

    /**
     * Send OTP to user's email
     * @param {boolean} isResend - If true, the OTP was requested to be
     * resent
     * @see https://dev.edufocal.com/docs/edufocal-api/branches/main/aa749906d9067-request-otp
     */
    sendOtp(isResend) {
        const email = this.emailField.value;
        const verbiage = isResend ? 'resent' : 'sent';

        // Send OTP to email
        return fetch(`${this.baseApiUrl}/oauth/otp/request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
            signal: this.abortSendOtp.signal,
        })
            .then(response => {
                if (response.status === 400) {
                    throw new Error("Failed to send otp");
                }
                return response.json()
            }).then(data => {

                const timeoutMinutes = this.getMinutesFromNow(data.timeout);

                if (isResend) {
                    this.resendOtpBtnWrapper.classList.add('hidden');
                    this.otpModal.querySelector('.success-card').classList.remove('hidden');

                    // Update OTP message
                    this.otpTitle.innerHTML = `We ${verbiage} an OTP to <strong>${email}</strong>. It will expire in <strong>${timeoutMinutes}</strong> minutes.`;
                    return;
                }

                return { timeoutMinutes, verbiage };
            })
            .catch(er => {
                if (er.name === 'AbortError') return;
                throw new Error(er);
            });
    }

    /**
     * Takes a datetime stamp and returns how far
     * away it is from the current time in minutes
     * @param {string} timestamp - a datetime stamp
     * @returns {number} the difference in minutes
     * @example
     * const time = '2021-03-01T12:00:00.000Z';
     * const minutes = getMinutesFromNow(time);
     * console.log(minutes); // 10
     */
    getMinutesFromNow(timestamp) {
        const now = new Date();
        const then = new Date(timestamp);
        const diff = then.getTime() - now.getTime();
        return Math.round(diff / (1000 * 60));
    }
}