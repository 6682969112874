import { setApiSessionToken, createLoadingSpinner } from "../utils";

export default class AccountCreationScreen {

    constructor({ email, modalContainer, onSignInRequired, onAccountCreationCompleted, baseApiUrl }) {

        this.modalContainer = modalContainer;
        this.email = email;
        this.onSignInRequired = onSignInRequired;
        this.baseApiUrl = baseApiUrl;
        this.onAccountCreationCompleted = onAccountCreationCompleted;

        /** Terms and conditions checkbox */
        this.termsCheckbox = document.querySelector('#terms-checkbox');

        /** "Create Your Account" modal */
        this.createAccountModal = document.querySelector('#create-account-modal');

        /** "Account Information" Modal */
        this.accountInfoModal = document.querySelector('#account-info-modal');

        /** Email input field in enrol modal */
        this.emailInput = document.querySelector('#enrol-modal-container #email-modal input');

        /** Student first name input field */
        this.studentFirstNameInput = document.querySelector('#student-first-name');

        /** Student last name input field */
        this.studentLastNameInput = document.querySelector('#student-last-name');

        /** Student email input field */
        this.studentEmailInput = document.querySelector('#student-email');

        /** School selection dropdown */
        this.schoolSelect = document.querySelector('#school-select');

        /** Parent/Guardian name input field */
        this.parentNameInput = document.querySelector('#guardian-name');

        /** Parent/Guardian email input field */
        this.parentEmailInput = document.querySelector('#guardian-email');

        /** Parent/Guardian phone input field */
        this.phoneNumberInput = document.querySelector('#guardian-phone');

        /** School list inside dropdown */
        this.schooList = document.querySelectorAll('#school-select option');

        /** "Continue" Button in "Create Your Account" modal */
        this.createAccountBtn = document.querySelector('#create-account-btn');

        /** Password input field in enrol modal */
        this.passwordInput = document.querySelector('#enrol-password');

        /** Confirm Password input field in enrol modal */
        this.confirmPasswordInput = document.querySelector('#enrol-confirm-password');

        /** Hide password icon in password field */
        this.hideInitialPassword = document.querySelector('#initial-password .p-hide');

        /** Show password icon in password field */
        this.showInitialPassword = document.querySelector('#initial-password .p-show');

        /** Hide password icon in confirm password field */
        this.hideConfirmPassword = document.querySelector('#confirm-password .p-hide');

        /** Show password icon in confirm password field */
        this.showConfirmPassword = document.querySelector('#confirm-password .p-show');

        /** Error message for email input in "Create Your Account" Modal */
        this.emailErrorMessage = document.querySelector('#create-account-email-error');

        /** Error message for password input in "Create Your Account" Modal */
        this.passwordErrorMessage = document.querySelector('#password-error');

        /** Error message for confirm password input in "Create Your Account" Modal */
        this.confirmPasswordErrorMessage = document.querySelector('#confirm-password-error');

        /** "Sign in" button on "Create Your Account Modal" */
        this.signInBtn = document.querySelector('#open-sign-in');

        /** Error message for invalid input in student first name field */
        this.studentFirstNameErrorMessage = document.querySelector('#student-first-name-error');

        /** Error message for invalid input in student first last name field */
        this.studentLastNameErrorMessage = document.querySelector('#student-last-name-error');

        /** Error message when unable to create account */
        this.createAccountError = document.querySelector('#create-account-error');

        /** Error message for invalid input in parent name field */
        this.parentNameErrorMessage = document.querySelector('#guardian-name-error');

        /** Error message for invalid input in parent email field */
        this.parentEmailErrorMessage = document.querySelector('#guardian-email-error');

        /** Error message for invalid input in contact number field */
        this.phoneNumberErrorMessage = document.querySelector('#phone-number-error');

        /** Button in "Account Information" modal */
        this.accountInfoBtn = document.querySelector('#account-info-modal #continue-btn');

        /**
         * The current cursor (page) for the API call to get the list of schools
         * @see https://dev.edufocal.com/docs/edufocal-api/8e1d30a092be1-list-schools
         */
        this.currentOffset = null;

        this.schoolSearchInput = document.getElementById('search-input');
        this.schoolDropdown = document.getElementById('dropdown');
        this.schoolDropdownList = document.getElementById('dropdown-list');
        this.loadingIndicator = document.getElementById('loading-indicator');

        this.isSchoolsLoading = false;
        this.scrollbarComp = 10;
        this.lastSelectedSchool = '';

        this.debouncedFetchData = this.debounce((query, offset) => {
            this.fetchSchools(query, offset);
        }, 300);

        this.schoolSearchInput.addEventListener('input', e => {
            const query = e.target.value;
            // this.currentOffset = null;
            this.debouncedFetchData(query, this.currentOffset);
        });

        /** Error message for invalid school selection */
        this.invalidSchoolError = document.querySelector('#school-error-msg');

        /** Tooltip text for contact number */
        this.contactNumberTooltip = document.querySelector('#contact-number-tooltip');

        /** Tooltip icon */
        this.tooltipIcon = document.querySelector('#tooltip-icon');

        this.schoolDropdown.addEventListener('scroll', () => {
            if (this.isSchoolsLoading) return; // don't fetch data if we're already fetching

            const { scrollTop, scrollHeight, clientHeight } = this.schoolDropdown;
            if (scrollTop + clientHeight + this.scrollbarComp >= scrollHeight) {
                if (!this.currentOffset) return; // no more data to fetch
                this.fetchSchools(this.schoolSearchInput.value, this.currentOffset);
            }
        });

        this.schoolDropdownList.addEventListener('click', e => {
            if (e.target && e.target.nodeName === 'LI') {
                this.schoolSearchInput.value = e.target.textContent;
                this.lastSelectedSchool = this.schoolSearchInput.value;
                this.selectedSchoolID = e.target.getAttribute('data-id');
                // this.currentOffset = null;
                this.schoolDropdown.style.display = 'none';
            }
        });

        this.hideInitialPassword.addEventListener(
            'click',
            this.togglePasswordVisibility.bind(this, 'initial')
        );

        this.showInitialPassword.addEventListener(
            'click',
            this.togglePasswordVisibility.bind(this, 'initial')
        );

        this.hideConfirmPassword.addEventListener(
            'click',
            this.togglePasswordVisibility.bind(this, 'confirm')
        );

        this.showConfirmPassword.addEventListener(
            'click',
            this.togglePasswordVisibility.bind(this, 'confirm')
        );

        this.confirmPasswordInput.addEventListener('paste', e => {
            e.preventDefault(); // prevent pasting into input
        });

        this.createAccountBtn.addEventListener('click', this.handleAccountInfoModal.bind(this));

        this.accountInfoBtn.addEventListener('click', this.attemptAccountCreation.bind(this));

        // When the user clicks outside the school select box, hide the school select options
        document.addEventListener('click', e => {
            if (!this.schoolDropdown.contains(e.target) && !this.schoolSearchInput.contains(e.target)) {
                this.schoolDropdown.style.display = 'none';
                this.schoolSearchInput.value = this.lastSelectedSchool;
            }
        });

        this.termsCheckbox.addEventListener('change', () => {
            this.createAccountBtn.disabled = !this.termsCheckbox.checked;
        });

        this.signInBtn.addEventListener('click', this.onSignInRequired);

        this.tooltipIcon.addEventListener('mouseenter', () => {
            this.contactNumberTooltip.style.visibility = 'visible';
            this.contactNumberTooltip.style.opacity = 1;
        });

        this.tooltipIcon.addEventListener('mouseleave', () => {
            this.contactNumberTooltip.style.visibility = 'hidden';
            this.contactNumberTooltip.style.opacity = 0;
        });

        this.confirmPasswordInput.addEventListener('keyup', e => {
            // Checks for enter key press
            if (e.keyCode === 13) {
                this.handleAccountInfoModal(e);
            }
        });
    }

    setup() {
        this.modalContainer.classList.add('is--open');
        document.body.classList.add('no-scroll');
        this.createAccountModal.classList.remove('hidden');

        const passwordInput = document.querySelector('#enrol-password');
        const emailInput = document.querySelector('#enrol-email');
        const emailVal = this.email;

        if (emailVal) {
            emailInput.value = emailVal;
            passwordInput.focus();
        } else {
            emailInput.focus();
        }
    }

    tearDown() {
        document.body.classList.remove('no-scroll');
        this.modalContainer.classList.remove('is--open');
        this.accountInfoModal.classList.add('hidden');
        this.resetInputFields();
        this.clearErrorMessages();
        this.termsCheckbox.checked = false;
        this.createAccountBtn.disabled = true;
    }

    /**
     * Check if string is a valid email address
     */
    validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    /**
     * Handles the process of opening the "Account Information"
     * modal window where the user can enter their account
     * information after some inputs related to creating a
     * new account have been validated.
     */
    handleAccountInfoModal(e) {
        e.preventDefault();
        if (!this.validateCreateAccountInputs()) return;

        this.createAccountModal.classList.add('hidden');
        this.accountInfoModal.classList.remove('hidden');
    }

    /**
     * Check if string is a valid password (8 or more characters)
     * @param {string} password - The password to validate
     */
    validatePassword(password) {
        return password.length >= 8;
    }

    /**
     * Attempts to create an account then call onAccountCreationCompleted
     */
    attemptAccountCreation(e) {
        e.preventDefault();
        if (!this.validateAccountInfoInputs()) return;

        // Clear error states
        document.querySelectorAll('#account-info-modal .error').forEach(card => {
            card.classList.add('hidden');
        });
        document.querySelectorAll('#account-info-modal input').forEach(input => {
            input.classList.remove('form__input-error');
        });

        // Add loading effect
        const loader = createLoadingSpinner();
        this.accountInfoBtn.classList.add('hidden', 'no-clicks');
        this.accountInfoModal.querySelector('.modal__content__footer').appendChild(loader);

        this.createAccount()
            .then(() => {
                this.onAccountCreationCompleted()
            })
            .catch(() => {
                this.createAccountError.classList.remove('hidden');
                this.accountInfoBtn.classList.remove('hidden', 'no-clicks');
                loader.remove();
            });
    }

    /**
     * Clears all input fields
     * and resets their error state
     */
    resetInputFields() {
        document.querySelectorAll('#enrol-modal-container input').forEach(input => {
            input.value = '';
            input.classList.remove('form__input-error');
        });
    }

    /**
     * Clear all error messages
     */
    clearErrorMessages() {
        document.querySelectorAll('#enrol-modal-container .error').forEach(error => {
            error.classList.add('hidden');
        });
        document.querySelectorAll('#enrol-modal-container .error-card').forEach(card => {
            card.classList.add('hidden');
        });
    }

    /**
     * Toggle password visibility
     * @param {string} type - The type of password field to toggle visibility for (initial or confirm)
     */
    togglePasswordVisibility(type) {
        if (type === 'initial') {
            this.hideInitialPassword.classList.toggle('hidden');
            this.showInitialPassword.classList.toggle('hidden');
            this.passwordInput.type = this.passwordInput.type === 'password' ? 'text' : 'password';
        } else if (type === 'confirm') {
            this.hideConfirmPassword.classList.toggle('hidden');
            this.showConfirmPassword.classList.toggle('hidden');
            this.confirmPasswordInput.type =
                this.confirmPasswordInput.type === 'password' ? 'text' : 'password';
        }
    }

    /**
     * Validate phone number
     * @param {string} phoneNumber - The phone number to validate
     * @returns {boolean} Returns true if the phone number is 10-11 digits long
     */
    validatePhoneNumber(phoneNumber) {
        // Remove whitespace
        phoneNumber = phoneNumber.replace(/\s/g, '');
        // Check if the phone number contains only digits
        const isOnlyDigits = /^\d+$/.test(phoneNumber);

        // Check if the phone number contains only digits and is 10-11 digits long
        return !(!isOnlyDigits || phoneNumber.length < 10 || phoneNumber.length > 11);


    }

    /**
     * Fetch schools from API
     * @see https://dev.edufocal.com/docs/edufocal-api/8e1d30a092be1-list-schools
     * @param {string} query - The search query
     * @param {string | null} offset - The offset to start fetching schools from
     */
    fetchSchools(query, offset) {
        if (this.isSchoolsLoading) return;

        if (document.querySelector('#no-results')) {
            document.querySelector('#no-results').remove();
        }
        this.isSchoolsLoading = true;
        this.showLoading();

        const url = `${this.baseApiUrl}/onboarding/schools?starting_at=${offset}&filter[name-filter][condition][value]=${query}&filter[name-filter][condition][path]=name&filter[name-filter][condition][operator]=like`;

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                this.isSchoolsLoading = false;
                this.hideLoading();

                if (!this.offset) {
                    this.schoolSearchInput.placeholder = 'Search for your school';
                    this.schoolDropdownList.innerHTML = '';
                } else {
                    this.hideLoading();
                }
                this.appendSchools(data.schools);
                this.currentOffset = data.starting_at;
                this.schoolDropdown.style.display = 'block';
            })
            .catch(() => {
                this.isSchoolsLoading = false;
                this.hideLoading();
                // add error message to dropdown
                const li = document.createElement('li');
                li.textContent = 'Error fetching schools. Please try again.';
                li.style.pointerEvents = 'none';
                li.setAttribute('aria-disabled', 'true');
                li.setAttribute('id', 'no-results');
                li.style.textAlign = 'center';
                li.style.fontWeight = '600';
                li.style.color = 'red';
                this.schoolDropdownList.appendChild(li);
                this.schoolDropdown.style.display = 'block';
            });
    }

    /**
     * Append schools to dropdown
     * @param {Array} items - The schools to append to the dropdown
     */
    appendSchools(items) {
        // If no results, show "School not found" message
        if (items.length === 0) {
            const li = document.createElement('li');
            li.textContent = `No school found for "${this.schoolSearchInput.value}"`;
            li.style.pointerEvents = 'none';
            li.setAttribute('aria-disabled', 'true');
            li.setAttribute('id', 'no-results');
            li.style.textAlign = 'center';
            li.style.fontWeight = '600';
            li.style.color = 'red';
            this.schoolDropdownList.appendChild(li);
            return;
        }

        items.forEach(item => {
            const li = document.createElement('li');
            li.textContent = item.name;
            li.setAttribute('data-id', item.id);
            this.schoolDropdownList.appendChild(li);
        });
    }

    showLoading() {
        this.loadingIndicator.style.display = 'block';
    }

    hideLoading() {
        this.loadingIndicator.style.display = 'none';
    }

    /**
     * Returns a new function that can only be called once every wait milliseconds.
     * If the function is called multiple times within that time period,
     * the function will only be invoked once.
     * @param {Function} func - The function to be debounced
     * @param {number} wait - The time in milliseconds to wait before invoking the function
     * @returns
     */
    debounce(func, wait) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    /**
     * Create a new user account
     * @see https://dev.edufocal.com/docs/edufocal-api/branches/main/0892294d798a0-create-account
     */
    async createAccount() {
        const email = this.emailInput.value;
        const password = this.passwordInput.value;
        const firstName = this.studentFirstNameInput.value;
        const lastName = this.studentLastNameInput.value;
        const schoolID = this.selectedSchoolID;
        const category = 'gsat';

        // clear error message
        this.createAccountError.classList.add('hidden');

        try {
            const response = await fetch(`${this.baseApiUrl}/onboarding/registration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                    first_name: firstName,
                    last_name: lastName,
                    school_id: schoolID,
                    exam_level: category,
                }),
            });

            const data = await response.json();
            if (data.status === 'success') {
                setApiSessionToken(data.token);
            } else {
                throw new Error('Unable to create account');
            }
        } catch (error) {
            throw Error(error);
        }
    }

    /**
     * Validate inputs in "Create Your Account" modal
     * @returns {boolean} Returns true if all inputs are valid
     * @returns {boolean} Returns false if any input is invalid
     * @returns {boolean} Returns false if the password and confirm password fields do not match
     */
    validateCreateAccountInputs() {
        // reset error states
        this.createAccountModal.querySelectorAll('.form__input-error').forEach(input => {
            input.classList.remove('form__input-error');
        });
        // reset error messages
        this.createAccountModal.querySelectorAll('.input-error-msg').forEach(errorMessage => {
            errorMessage.classList.add('hidden');
        });

        // store validity states
        const validity = {
            isEmailValid: true,
            isPasswordValid: true,
            doPasswordsMatch: true,
        };

        const email = this.emailInput.value;
        const password = this.passwordInput.value;
        const confirmPassword = this.confirmPasswordInput.value;

        const isEmailValid = this.validateEmail(email);
        const isPasswordValid = this.validatePassword(password);
        const doPasswordsMatch = password === confirmPassword;

        if (!isEmailValid) {
            validity.isEmailValid = false;
            this.emailErrorMessage.classList.remove('hidden');
            this.emailInput.classList.add('form__input-error');
        }

        if (!isPasswordValid) {
            validity.isPasswordValid = false;
            this.passwordErrorMessage.classList.remove('hidden');
            this.passwordInput.classList.add('form__input-error');
            this.confirmPasswordInput.classList.add('form__input-error');
        }

        if (isPasswordValid && !doPasswordsMatch) {
            validity.doPasswordsMatch = false;
            this.passwordErrorMessage.classList.add('hidden');
            this.confirmPasswordErrorMessage.classList.remove('hidden');
            this.confirmPasswordInput.classList.add('form__input-error');
            this.passwordInput.classList.add('form__input-error');
        }

        return validity.isEmailValid && validity.isPasswordValid && validity.doPasswordsMatch;
    }

    /**
     * Validate inputs in "Account Information" modal
     * @returns {boolean} Returns true if all inputs are valid
     * @returns {boolean} Returns false if any input is invalid
     */
    validateAccountInfoInputs() {
        // reset error states
        this.accountInfoModal.querySelectorAll('input').forEach(input => {
            input.classList.remove('form__input-error');
        });
        // reset error messages
        this.accountInfoModal.querySelectorAll('.input-error-msg').forEach(errorMessage => {
            errorMessage.classList.add('hidden');
        });

        // store validity states
        const validity = {
            isStudentFirstNameValid: true,
            isStudentLastNameValid: true,
            isParentNameValid: true,
            isParentEmailValid: true,
            isPhoneNumberValid: true,
            isSchoolValid: true,
        };

        const studentFirstName = this.studentFirstNameInput.value;
        const studentLastName = this.studentLastNameInput.value;
        const parentName = this.parentNameInput.value;
        const parentEmail = this.parentEmailInput.value;
        const phoneNumber = this.phoneNumberInput.value;
        const school = this.selectedSchoolID;

        const isStudentFirstNameValid = studentFirstName.length > 0;
        const isStudentLastNameValid = studentLastName.length > 0;
        const isParentNameValid = parentName.length > 0;
        const isParentEmailValid = this.validateEmail(parentEmail);
        const isPhoneNumberValid = this.validatePhoneNumber(phoneNumber);
        const isSchoolValid = school && school !== '1';

        if (!isSchoolValid) {
            validity.isSchoolValid = false;
            this.invalidSchoolError.classList.remove('hidden');
        }

        if (!isStudentFirstNameValid) {
            validity.isStudentFirstNameValid = false;
            this.studentFirstNameErrorMessage.classList.remove('hidden');
            this.studentFirstNameInput.classList.add('form__input-error');
        }

        if (!isStudentLastNameValid) {
            validity.isStudentLastNameValid = false;
            this.studentLastNameErrorMessage.classList.remove('hidden');
            this.studentLastNameInput.classList.add('form__input-error');
        }

        if (!isParentNameValid) {
            validity.isParentNameValid = false;
            this.parentNameErrorMessage.classList.remove('hidden');
            this.parentNameInput.classList.add('form__input-error');
        }

        if (!isParentEmailValid) {
            validity.isParentEmailValid = false;
            this.parentEmailErrorMessage.classList.remove('hidden');
            this.parentEmailInput.classList.add('form__input-error');
        }

        if (!isPhoneNumberValid) {
            validity.isPhoneNumberValid = false;
            this.phoneNumberErrorMessage.classList.remove('hidden');
            this.phoneNumberInput.classList.add('form__input-error');
        }

        return validity.isStudentFirstNameValid &&
            validity.isStudentLastNameValid &&
            validity.isParentNameValid &&
            validity.isParentEmailValid &&
            validity.isPhoneNumberValid &&
            validity.isSchoolValid;
    }
}