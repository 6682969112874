'use strict';

import SignIn from '../sign-in/sign-in.js';
import EmailInputScreen from "./email-input-screen";
import PlanSelectionScreen from "./plan-selection-screen";
import AccountCreationScreen from "./account-creation-screen";
import { getApiDomainToken } from "../utils";


const states = {
  CHECK_AUTH: 'CHECK_AUTH',
  ENTER_EMAIL: 'ENTER_EMAIL',
  ACCOUNT_CREATION: 'ACCOUNT_CREATION',
  LOGIN: 'LOGIN',
  PROGRAMME_SELECTION: 'PROGRAMME_SELECTION',
};

/**
 * This class handles the enrolment process.
 */
export default class Enrol {

  constructor(options) {

    this.currentScreen = null;

    /** The currently selected programme */
    this.selectedProgramme = null;

    this.signIn = options?.signIn || new SignIn();

    /** Base URL for API calls */
    this.baseApiUrl = `${import.meta.env.VITE_EDUFOCAL_API}`;

    /** Enrol buttons */
    this.enrolBtns = document.querySelectorAll('.enrol-btn');

    /** Modal Container */
    this.modalContainer = document.querySelector('#enrol-modal-container');

    /** Modal container close button */
    this.closeModalBtn = document.querySelectorAll('.modal__close');

    /** Modal container back button */
    this.backModalBtn = document.querySelectorAll('.modal__back-btn');

    /** Modals */
    this.modals = document.querySelectorAll('.modal');

    this.enrolBtns.forEach(btn => {
      btn.addEventListener('click', (e) => {
        this.selectedProgramme = e.target.getAttribute('data-category');
        this.transitionToState(states.CHECK_AUTH, { category: this.selectedProgramme });
      });
    });

    document.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.endEnrolProcess();
      }
    });

    this.closeModalBtn.forEach(btn => {
      btn.addEventListener('click', this.endEnrolProcess.bind(this));
    });

    this.backModalBtn.forEach(btn => {
      btn.addEventListener('click', this.goBack.bind(this));
    });

    this.state = states.CHECK_AUTH;
  }

  transitionToState(state, data) {

    this.state = state;

    switch(this.state) {
      case states.CHECK_AUTH:
        getApiDomainToken().then((token) => {
          if (token) {
              this.state = states.PROGRAMME_SELECTION;
            } else {
              this.state = states.ENTER_EMAIL;
            }
            this.transitionToState(this.state, data);
        })
        break;

      case states.ENTER_EMAIL:
        this.changeScreenUI(
            new EmailInputScreen({
              onSignInRequired: this.onSignInRequired.bind(this),
              onAccountCreationRequired: this.onAccountCreationRequired.bind(this),
              ...this.defaultOptions(),
          })
        );

        break;

      case states.ACCOUNT_CREATION:
        this.changeScreenUI(
            new AccountCreationScreen({
              email: data.email,
              onSignInRequired: this.onSignInRequired.bind(this),
              onAccountCreationCompleted: this.onAccountCreationCompleted.bind(this),
              ...this.defaultOptions(),
            })
        );
        break;

      case states.LOGIN:

        this.currentScreen?.tearDown();
        this.signIn.initSignInProcess(data.email, this.onSignInSuccess.bind(this));
        break;

      case states.PROGRAMME_SELECTION:
        this.changeScreenUI(
            new PlanSelectionScreen({
              category: data.category,
              ...this.defaultOptions(),
            })
        );
        break;
    }

  }

  defaultOptions() {
    return {
      modalContainer: this.modalContainer,
      baseApiUrl: this.baseApiUrl,
    }
  }

  changeScreenUI(newScreen) {
    this.currentScreen?.tearDown();
    this.currentScreen = newScreen;
    this.currentScreen.setup();
  }

  onSignInRequired(email) {
    this.transitionToState(states.LOGIN, { email: email });
  }

  onAccountCreationRequired(email) {
    this.transitionToState(states.ACCOUNT_CREATION, { email: email });
  }

  onSignInSuccess() {
    this.transitionToState(states.PROGRAMME_SELECTION, { category: this.selectedProgramme });
  }

  onAccountCreationCompleted() {
    this.signIn.setupLoggedInUser();
    this.transitionToState(states.PROGRAMME_SELECTION, { category: this.selectedProgramme });
  }

  /**
   * Replaces enrol buttons with navigate to dashboard buttons
   */
  setEnrolToGoToDashboard() {
    this.enrolBtns.forEach(btn => {
      btn.textContent = 'Go to Dashboard';
      const anchor = document.createElement('a');
      anchor.href = `${import.meta.env.VITE_EDUFOCAL_URL}`;
      anchor.appendChild(btn.cloneNode(true));
      btn.parentNode.replaceChild(anchor, btn);
    });
  }

  /**
   * Closes the enrol modal container and resets all states
   */
  endEnrolProcess() {
    this.currentScreen?.tearDown();
    this.modals.forEach(modal => {
      modal.classList.add('hidden');
    });
  }

  /**
   * Goes back to the previous screen
   */
  goBack() {
    this.endEnrolProcess();
    this.transitionToState(states.PROGRAMME_SELECTION, { category: this.selectedProgramme });
  }
}
