import {createLoadingSpinner, setApiSessionToken} from "../utils";

export default class PasswordInputScreen {
    constructor({ email, baseApiUrl, modalContainer, onSignInRequested, onLoginSucceeded, onForgotPasswordRequested }) {

        this.modalContainer = modalContainer;
        this.baseApiUrl = baseApiUrl;
        this.onSignInRequested = onSignInRequested;
        this.onLoginSucceeded = onLoginSucceeded;
        this.onForgotPasswordRequested = onForgotPasswordRequested;
        this.email = email;

        /** "Login" button on login modal */
        this.loginBtn = document.querySelector('#login-btn');

        /** Login modal */
        this.loginModal = document.querySelector('#login-modal');

        /** Email displayed on password modal */
        this.emailString = document.querySelector('.email-string');

        /** Password input field in login modal */
        this.passwordField = document.querySelector('#login-password');

        /** "Forgot Password" button */
        this.forgotPasswordBtn = document.querySelector('.forgot-password');

        /** Shows if the email/password combo is incorrect */
        this.passwordErrorMessage = document.querySelector('#sign-up__password-error');

        /** "Login" button wrapper in login modal */
        this.loginBtnWrapper = document.querySelector('#login-modal-btn-wrapper');

        /** Show password icon */
        this.showPassword = document.querySelector('.p-show');

        /** Hide password icon */
        this.hidePassword = document.querySelector('.p-hide');

        /** "Use a different email" button */
        this.useDifferentEmailBtn = document.querySelector('.use-different-email');

        /** Email input field */
        this.emailField = document.querySelector('#signup-email');

        this.abortLogin = new AbortController();

        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
        this.login = this.login.bind(this);
    }

    setup() {
        document.body.classList.add('no-scroll');
        this.loginModal.classList.remove('hidden');
        this.modalContainer.classList.add('is--open');
        this.loginModal.classList.remove('hidden');

        if (this.email && typeof this.email === 'string') {
            this.emailField.value = this.email;
        }

        this.emailString.innerHTML = this.emailField.value;
        this.passwordField.focus();
        this.passwordField.value = '';

        this.loginBtn.addEventListener('click', this.login);
        this.loginModal.addEventListener('submit', this.login);
        this.useDifferentEmailBtn.addEventListener('click', this.onSignInRequested);
        this.showPassword.addEventListener('click', this.togglePasswordVisibility);
        this.hidePassword.addEventListener('click', this.togglePasswordVisibility);

        this.forgotPasswordBtn.addEventListener('click', this.onForgotPasswordRequested);

        this.abortLogin = new AbortController();
    }

    tearDown() {
        this.clearLoginErrors();
        document.body.classList.remove('no-scroll');
        this.loginModal.classList.add('hidden');
        this.modalContainer.classList.remove('is--open');
        this.forgotPasswordBtn.removeEventListener('click', this.onForgotPasswordRequested);
        this.hidePassword.removeEventListener('click', this.togglePasswordVisibility);
        this.showPassword.removeEventListener('click', this.togglePasswordVisibility);
        this.useDifferentEmailBtn.removeEventListener('click', this.onSignInRequested);
        this.loginBtn.removeEventListener('click', this.login);
        this.loginModal.removeEventListener('submit', this.login);
        this.abortLogin.abort();
    }

    /**
     * Login User
     */
    login(e) {
        e.preventDefault();

        // reset error messages
        this.loginModal.querySelectorAll('.input-error-msg').forEach(el => {
            el.classList.add('hidden');
        });

        const email = this.emailField.value;
        const password = this.passwordField.value;
        const loader = createLoadingSpinner();

        const url = `${this.baseApiUrl}/login`;
        const data = {
            email,
            password,
        };

        if (!password) {
            this.shakeInput(this.passwordField);
            this.passwordField.focus();
            return;
        }

        // Add loading spinner to login modal
        this.loginBtn.classList.add('hidden');
        this.useDifferentEmailBtn.classList.add('hidden');
        this.loginBtnWrapper.appendChild(loader);
        this.loginBtnWrapper.classList.add('no-clicks');

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            signal: this.abortLogin.signal,
        })
            .then(res => res.json())
            .then(res => {
                if (res.token) {
                    if (setApiSessionToken(res.token)) {
                        this.loginModal.classList.add('hidden');
                        this.onLoginSucceeded();
                    }
                }
            })
            .catch(() => {
                this.loginModal.querySelector('.error-card').classList.remove('hidden');
                this.useDifferentEmailBtn.classList.remove('hidden');
            })
            .finally(() => {
                // Remove loading elem and show login button
                loader.remove();
                this.loginBtn.classList.remove('hidden');
                this.loginBtnWrapper.classList.remove('no-clicks');
            });
    }

    /**
     * Toggle password visibility
     */
    togglePasswordVisibility() {
        if (this.passwordField.type === 'password') {
            this.passwordField.type = 'text';
            this.showPassword.classList.add('hidden');
            this.hidePassword.classList.remove('hidden');
        } else {
            this.passwordField.type = 'password';
            this.showPassword.classList.remove('hidden');
            this.hidePassword.classList.add('hidden');
        }
    }

    /**
     * Clear errors in login modal
     */
    clearLoginErrors() {
        this.loginModal.querySelectorAll('.input-error-msg').forEach(msg => {
            msg.classList.add('hidden');
        });
    }

    /**
     * Shakes an input element to indicate an error
     * @param {HTMLElement} input Input element to shake
     */
    shakeInput(input) {
        input.classList.add('shake');
        setTimeout(() => {
            input.classList.remove('shake');
        }, 500);
    }
}