export default class LoginSuccessScreen {

    constructor({displayTime, modalContainer }) {
        this.modalContainer = modalContainer;
        this.timeLeft = displayTime;
        /** Redirect message that appears on the login success modal */
        this.redirectMessage = document.querySelector('.redirect-msg');
        /** Login success modal */
        this.loginSuccessModal = document.querySelector('#login-success-modal');
    }

    setup()
    {
        document.body.classList.add('no-scroll');
        this.modalContainer.classList.add('is--open');
        this.loginSuccessModal.classList.remove('hidden');

        const updateTimeoutMessage = (timeLeft) => {
            this.redirectMessage.innerHTML = `Reloading in <strong>${timeLeft}</strong>...`;
        }

        updateTimeoutMessage(this.timeLeft);

        const interval = setInterval(() => {
            this.timeLeft -= 1;
            if (this.timeLeft === 0) {
                clearInterval(interval);
                location.reload();
            }
            updateTimeoutMessage(this.timeLeft);
        }, 1000);
    }

    tearDown() {
        this.redirectMessage = null;
        document.body.classList.remove('no-scroll');
        this.loginSuccessModal.classList.add('hidden');
        this.modalContainer.classList.remove('is--open');
    }
}