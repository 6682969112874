'use strict';

// Handle navbar on mobile
window.addEventListener('load', function () {
  // Navbar open/close
  let navbar = document.querySelector('.nav-links');
  let menuButton = document.querySelector('.hamburger:not(.open)');

  // Toggle navbar class
  if (menuButton) {
    menuButton.addEventListener('click', function () {
      navbar.classList.toggle('open');
      menuButton.classList.toggle('open');
    });
  }
});

// Add active class to current page
let activePage = window.location.pathname.split('/');
activePage = activePage.filter(page => page !== '');

const navLinks = document.querySelectorAll('.nav-links a');

navLinks.forEach(link => {
  if (link.href.includes(activePage[0])) {
    link.classList.add('active-page');
  }
})

// Handle wave element displacement
function handleWaveDisplacement() {
  const waveTops = document.querySelectorAll('.wave-top');
  const waveBottoms = document.querySelectorAll('.wave-bottom');
  
  waveTops.forEach(function(waveTop) {
    waveTop.style.top = waveTop.offsetHeight * -1 + 10 + 'px';
  });
  
  waveBottoms.forEach(function(waveBottom) {
    waveBottom.style.bottom = waveBottom.offsetHeight * -1 + 10 + 'px';
  });
}

window.addEventListener('resize', handleWaveDisplacement);
window.addEventListener('load', handleWaveDisplacement);