import { createLoadingSpinner } from "../utils";

export default class EmailInputScreen {

    /**
     *
     * @param email
     * @param baseApiUrl
     * @param onLoginRequested
     * @param onLoginSucceeded
     * @param modalContainer
     */
    constructor({ email, baseApiUrl, onLoginRequested, onLoginSucceeded, modalContainer }) {
        this.baseApiUrl = baseApiUrl;
        this.onLoginRequested = onLoginRequested;
        this.onLoginSucceeded = onLoginSucceeded;
        this.modalContainer = modalContainer;
        this.email = email;

        this.verifyEmail = this.verifyEmail.bind(this);

        this.abortCheckEmail = new AbortController();
        /** "Continue" button in email modal */
        this.emailContinueBtn = document.querySelector('#continue-btn-email');
        /** "Continue" button wrapper in email modal */
        this.emailContinueBtnWrapper = document.querySelector('#email-modal-btn-wrapper');
        /** Error message that shows if the email is invalid */
        this.emailErrorMessage = document.querySelector('#sign-in__email-error');
        /** Email input field */
        this.emailField = document.querySelector('#signup-email');
        /** Email modal */
        this.emailModal = document.querySelector('#email-modal');
    }

    setup() {
        document.body.classList.add('no-scroll');
        this.emailModal.classList.remove('hidden');
        this.modalContainer.classList.add('is--open');

        this.emailField.focus();
        // Bind event listeners
        this.emailContinueBtn.addEventListener('click', this.verifyEmail);
        this.emailModal.addEventListener('submit', this.verifyEmail);

        if (this.email && typeof this.email === 'string') {
            this.emailField.value = this.email;
            this.emailContinueBtn.click();
        }
        // Reset abort controllers
        this.abortCheckEmail = new AbortController();
    }

    tearDown() {
        document.body.classList.remove('no-scroll');
        this.emailModal.classList.add('hidden');
        this.modalContainer?.classList?.remove('is--open');

        this.emailContinueBtn.removeEventListener('click', this.verifyEmail);
        this.emailModal.removeEventListener('submit', this.verifyEmail);
        // Abort all fetch requests
        this.abortCheckEmail.abort();
    }

    /**
     * Check if string is a valid email address
     */
    validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    /**
     * Check if the email exists in the database
     * @see https://dev.edufocal.com/docs/edufocal-api/95603980bd232-email-check
     */
    checkEmail() {
        console.log("check email called");
        const email = this.emailField.value;
        const loadingElem = createLoadingSpinner();

        // Clear errors
        this.emailModal.querySelectorAll('.error').forEach(msg => {
            msg.classList.add('hidden');
        });

        // Add loading spinner to email modal and hide 'continue' button
        this.emailContinueBtnWrapper.appendChild(loadingElem);
        this.emailContinueBtn.classList.add('hidden');
        // disable 'continue' button to prevent multiple requests
        this.emailContinueBtnWrapper.classList.add('no-clicks');

        fetch(`${this.baseApiUrl}/oauth/check`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
            signal: this.abortCheckEmail.signal,
        })
            .then(data => {
                if (data.status === 429) {
                    // too many login attempts
                    this.emailModal.querySelector('#sign-in__too-many-attempts').classList.remove('hidden');
                }
                if (data.status === 404) {
                    // email doesn't exist
                    this.emailModal.querySelector('#sign-in__no-account').classList.remove('hidden');
                }
                if (data.status === 200) {
                    // email exists, show login modal
                    this.onLoginRequested({ email, onLoginSucceeded: this.onLoginSucceeded });
                }
            })
            .catch(er => {
                if (er.name === 'AbortError') return;
                console.error(er.message);
            })
            .finally(() => {
                // Remove loading elem and show continue button
                loadingElem.remove();
                this.emailContinueBtn.classList.remove('hidden');
                this.emailContinueBtnWrapper.classList.remove('no-clicks');
            });
    }

    /**
     * Check if email is valid and if it exists
     */
    verifyEmail(e) {
        e.preventDefault();
        // Remove error states if they exist
        this.emailErrorMessage.classList.add('hidden');
        this.emailField.classList.remove('form__input-error');

        if (this.validateEmail(this.emailField.value)) {
            // Email is valid, check if it exists
            this.checkEmail();
        } else {
            // Email is invalid, show error message
            this.emailErrorMessage.classList.remove('hidden');
            this.emailField.classList.add('form__input-error');
            this.emailField.focus();
        }
    }
}