'use strict';

import {getApiDomainToken, logout} from "../utils";
import LoginSuccessScreen from "./login-success-screen";
import EmailInputScreen from "./email-input-screen";
import PasswordInputScreen from "./password-input-screen";
import OtpRequestScreen from "./otp-request-screen";

const states = {
  CHECK_AUTH: 'CHECK_AUTH',
  ENTER_EMAIL: 'ENTER_EMAIL',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  REQUEST_OTP: 'REQUEST_OTP',
  LOGGED_IN: 'LOGGED_IN',
}

/**
 * This class handles the different steps of the sign in process
 */
export default class SignIn {
  constructor() {
    /** Base URL */
    this.baseApiUrl = `${import.meta.env.VITE_EDUFOCAL_API}`;

    /** Pofile Page URL */
    this.baseUrl = `${import.meta.env.VITE_EDUFOCAL_URL}`;

    /** Sign in Modal Container */
    this.modalContainer = document.querySelector('#sign-in-modal-container');

    /** Sign in button */
    this.signInBtn = document.querySelector('#sign-in-btn');

    /** Close modal button */
    this.closeModalBtn = document.querySelectorAll('.modal__close');

    /** Modals */
    this.modals = document.querySelectorAll('.modal');

    this.signInBtn.addEventListener('click', this.initSignInProcess.bind(this));

    this.closeModalBtn.forEach(btn => {
      btn.addEventListener('click', this.endSignInProcess.bind(this));
    });

    this.setupLoggedInUser();

    document.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.endSignInProcess();
      }
    });

    this.state = states.CHECK_AUTH;
    this.currentScreen = null;
  }

  defaultOptions() {
    return {
      modalContainer: this.modalContainer,
      baseApiUrl: this.baseApiUrl,
    }
  }

  async transitionToState(state, data) {
    this.state = state;

    switch (this.state) {
      case states.CHECK_AUTH:
        // Check if user is logged in
          getApiSessionToken().then( (token) => {
            if (token) {
              this.setupLoggedInUser();
              this.transitionToState(states.LOGGED_IN, {});
            } else {
              this.transitionToState(states.ENTER_EMAIL, data);
            }
          })
        if (getApiSessionToken()) {
          this.setupLoggedInUser();
          this.transitionToState(states.LOGGED_IN, {});
        } else {
          this.transitionToState(states.ENTER_EMAIL, data);
        }
        break;
      case states.ENTER_EMAIL:
        this.changeScreenUI(
            new EmailInputScreen({
              email: data.email,
              onLoginRequested: this.onLoginRequested.bind(this),
              onLoginSucceeded: data?.onLoginSucceeded || this.onLoginSucceeded.bind(this),
              ...this.defaultOptions()
            })
        );
        break;
      case states.LOGIN:
        this.changeScreenUI(
            new PasswordInputScreen({
              email: data.email,
              onLoginSucceeded: data.onLoginSucceeded || this.onLoginSucceeded.bind(this),
              onSignInRequested: this.onSignInRequested.bind(this),
              onForgotPasswordRequested: () => this.onForgotPasswordRequested({
                onLoginSucceeded: data.onLoginSucceeded || this.onLoginSucceeded.bind(this)
              }),
              ...this.defaultOptions()
            })
        );
        break;
      case states.LOGIN_SUCCESS:
        this.changeScreenUI(
            new LoginSuccessScreen({
              displayTime: 2,
              modalContainer: this.modalContainer
            })
        );
        break;
      case states.REQUEST_OTP:
        this.changeScreenUI(
            new OtpRequestScreen({
              onLoginSucceeded: data.onLoginSucceeded || this.onLoginSucceeded.bind(this),
              onOtpWindowClose: this.onOtpWindowClose.bind(this),
              ...this.defaultOptions(),
            })
        )
        break;
      case states.LOGGED_IN:
        this.currentScreen?.tearDown();
        this.currentScreen = null;
        //do nothing
        break;
    }
  }

  changeScreenUI(newScreen) {
    this.currentScreen?.tearDown();
    this.currentScreen = newScreen;
    this.currentScreen.setup();
  }

  setupLoggedInUser() {
    // Check if user is logged in
    getApiDomainToken().then((token) => {
      if (token) {
        this.fetchUserDetails().then(data => {
          this.replaceSignInBtn(data.first_name);
        }).catch((e) => { console.log(e)});
      }
    })
  }

  onSignInRequested() {
    this.transitionToState(states.ENTER_EMAIL, {});
  }

  onLoginRequested({ email, onLoginSucceeded }) {
    this.transitionToState(states.LOGIN, { email: email, onLoginSucceeded: onLoginSucceeded });
  }

  onLoginSucceeded() {
    this.transitionToState(states.LOGIN_SUCCESS, {});
  }

  onForgotPasswordRequested({ onLoginSucceeded }) {
    this.transitionToState(states.REQUEST_OTP, { onLoginSucceeded });
  }

  onOtpWindowClose() {
    this.endSignInProcess();
  }

  /**
   * Opens sign-in modal to begin the sign in process
   */
  initSignInProcess(email, onLoginSucceeded=null) {
    if (onLoginSucceeded) {
      const fromCaller = onLoginSucceeded;
      onLoginSucceeded = () => {
        this.setupLoggedInUser();
        fromCaller();
        this.endSignInProcess();
        this.transitionToState(states.LOGGED_IN, {});
      }
    }
    if (typeof email == 'string') {
      this.transitionToState(states.LOGIN, { email, onLoginSucceeded });
    } else {
      this.transitionToState(states.ENTER_EMAIL, {email: null, onLoginSucceeded});
    }
  }

  /**
   * Create dropdown menu for user
   * @param {string} name - User's first name
   */
  createDropdownMenu(name) {
    return `
    <div class="dropdown-profile">
      <button class="user-btn btn btn--dropdown" id="dropdown-menu-btn" type="button" aria-haspopup="true" aria-expanded="false">
        <span class="user-icon">
          <svg class="icon icon--user" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" fill="currentColor"></path>
          </svg>
        </span>
        <span class="dropdown__name">Hi, ${name}</span>
        <span class="dropdown__icon">
          <svg class="icon icon--dropdown" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 10.5L3.5 6l1-1 3.5 3.5L12.5 5l1 1z" fill="currentColor"></path>
          </svg>
        </span>
      </button>
      <div class="dropdown__menu" id="dropdown-menu" aria-labelledby="dropdown-menu-btn" role="menu">
        <!--<a class="dropdown__item" href="${this.baseUrl}/profile" role="menuitem">Profile</a>-->
        <a class="dropdown__item" href="${this.baseUrl}" role="menuitem">Dashboard</a>
        <!--<a class="dropdown__item" href="${this.baseUrl}/profile/subscription" role="menuitem">Subscription</a>-->
        <a id="logout-btn" class="dropdown__item" href="#" role="menuitem">Logout</a>
      </div>
    </div>
  `;
  }

  /**
   * Replaces sign in button with user name and dropdown menu
   * @param {string} name - User's name
   */
  replaceSignInBtn(name) {
    const signInBtn = document.querySelector('#sign-in-btn');
    const signInBtnWrapper = document.querySelector('#sign-in-btn-wrapper');

    signInBtnWrapper.innerHTML = this.createDropdownMenu(name);

    const dropdownMenu = document.querySelector('#dropdown-menu');
    const dropdownMenuBtn = document.querySelector('#dropdown-menu-btn');
    const logoutBtn = document.querySelector('#logout-btn');

    signInBtnWrapper.classList.add('dropdown-wrapper');
    signInBtnWrapper.removeAttribute('id');
    signInBtn?.remove();

    logoutBtn.addEventListener('click', logout);

    dropdownMenuBtn.addEventListener('click', e => {
      e.stopPropagation();
      dropdownMenu.classList.toggle('dropdown__menu--active');
    });

    document.addEventListener('click', e => {
      if (!dropdownMenu.contains(e.target)) {
        dropdownMenu.classList.remove('dropdown__menu--active');
      }
    });

    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        dropdownMenu.classList.remove('dropdown__menu--active');
      }
    });
  }

  /**
   * Fetch user details
   * @see https://dev.edufocal.com/docs/edufocal-api/083af5dd07d4e-get-profile
   */
  async fetchUserDetails() {
    const token = await getApiDomainToken();
    if (! token ) {
      return false;
    }
    const url = `${this.baseApiUrl}/dashboard/profile`;

    // replace sign in button with loading skeleton
    const signInBtnWrapper = document.querySelector('#sign-in-btn-wrapper');
    signInBtnWrapper.innerHTML = `
    <div class="skeleton-wrapper">
      <div class="skeleton skeleton--circle"></div>
      <div class="skeleton skeleton--line"></div>
    </div>
    `;
    signInBtnWrapper.classList.add('skeleton-wrapper--active');

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      return await response.json();
    } catch (error) {
      // re-add sign in button if there is an error
      signInBtnWrapper.innerHTML = `
      <button id="sign-in-btn" class="modal__open btn btn--primary btn--small">Sign In</button>
      `;
      //logout();
    } finally {
      signInBtnWrapper.classList.remove('skeleton-wrapper--active');
    }
  }

  /**
   * Closes the sign-in modal container and resets all states
   */
  endSignInProcess() {
    this.currentScreen?.tearDown();
    this.currentScreen = null;
    this.modalContainer.classList.remove('is--open');
    // Remove all error states
    const erroredInputs = this.modalContainer.querySelectorAll('.form__input-error');
    erroredInputs.forEach(function (message) {
      message.classList.remove('form__input-error');
    });
    const errorMessages = this.modalContainer.querySelectorAll('.input-error-msg');
    errorMessages.forEach(function (message) {
      message.classList.add('hidden');
    });
    // Reset modals
    this.modals.forEach(function (modal) {
      modal.classList.add('hidden');
    });
    // Reset input values
    const inputs = this.modalContainer.querySelectorAll('input');
    inputs.forEach(input => {
      input.value = '';
    });
  }

}